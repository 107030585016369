<template>
  <FormWrapper :is-loading="isLoading">
    <PageTitle
      title="Attendance Time In Out"
      slot="title"
      link="/helpContent/AttendanceTimeInOut"
    />
    <ValidationObserver ref="validator">
      <FormRow>
        <!-- <div class="col-sm-4">
          <FormSelect
            label="Company"
            :items="companyList"
            item-name="Name"
            item-value="id"
            v-model="form.companyId"
            rules="required"
          />
        </div> -->
        <div class="col-sm-2">
          <!-- <inputDate
            label="Date"
            v-model="form.attendancedate"
            rules="required"
          /> -->
                     <!-- sagar 05-09-2023 changes next date disable -->
                     <InputDate label="Date"   rules="required" :options="options" v-model="form.attendancedate" />
        </div>
      </FormRow>

      <FormRow>
        <div class="col-md-8" >
          <AttendanceComponent
            v-model="attendanceJson"
            v-if="showComp" />
        </div>
      </FormRow>
    </ValidationObserver>
    <FormRow>
      <div class="col-md-4">
        <ButtonGroup> <FormButton type="info" @click="()=>{$router.go(-1)}">Back</FormButton>
        <FormButton type="success" @click="sendData()">Save</FormButton></ButtonGroup>
      </div>
    </FormRow>
    <br />
    <Popup
      title="Success"
      type="success"
      :value="showMessage.isVisible"
      @close="() => (showMessage.isVisible = false)"
      :closable="true"
    >
      {{ showMessage.message }}
      <div slot="footer">
        <div slot="footer">
          <FormButton
            @click="
              () => {
                showMessage.isVisible = false;
              }
            "
            >Ok</FormButton
          >
        </div>
      </div>
    </Popup>
  </FormWrapper>
</template>

<script>
import FormWrapper from "Components/form/FormWrapper";
import PageTitle from "Components/layout/PageTitle";
import loadingMixin from "Mixins/loadingMixin";
import { getAllCompanyList, bulkAttendance } from "../api";
import { ValidationObserver } from "vee-validate";
import InputDate from "Components/form/InputDate";
import AttendanceComponent from "./AttendanceComponent";
export default {
  name: "AttendanceInOutHR",
  mixins: [loadingMixin],
  components: {
    FormWrapper,
    PageTitle,
    ValidationObserver,
    InputDate,
    AttendanceComponent,
  },
  computed: {
    userData() {
      return this.$store.state.user.user;
    },
  },
  data() {
    return {
      form: {
        companyId: null,
        attendancedate: "",
      },
      showMessage: {
        isVisible: false,
        message: "",
      },
      companyList: [],
      attendanceJson: [],
      showComp: false,
      options: {
disabledDate(date) {
  return date && date.valueOf() > Date.now();
  }
},
    };
  },
  watch: {
    "form.attendancedate": function(val) {
      if (val) {
        this.showComp = true;
      } else {
        this.showComp = false;
      }    
    },    
  },
  created() {
    this.getAllCompany();
  },
  methods: {
    getAllCompany() {
      getAllCompanyList().then((res) => {
        this.companyList = res.data;
      });
    },

    sendData() {
      this.$refs.validator.validate().then((result) => {
        if (result) {
          const data = {
            attendanceData: JSON.stringify(this.attendanceJson),
            dateOfAttendance:this.form.attendancedate,
          };
          this.showLoader();
          bulkAttendance(data)
            .then(this.handleResponse)
            .catch(this.handleError);
        }
      });
    },
    handleResponse(response) {
      this.hideLoader();
      this.showMessage.isVisible = true;
      this.showMessage.message = response.data[0].message;
      this.attendanceJson=[];
      this.form.attendancedate='';
      this.form={};
    },
  },
};
</script>
