<template>
  <div>
    <RecordItem
      v-for="(item, i) in value"
      :key="i"
      :readonly="readonly"
      :record-index="i"
      :length="value.length"
      @onAddRow="addRow"
      @onDeleteRow="deleteRow"
    >
      <!-- <EntityCompany :class="$style.indentClass" :i="i" v-model="value[i]" :readonly="readonly" />
             -->
      <FormRow>
        <div class="col-sm-4">
          <FormSelect
            label="Staff Name"
            :vid="`person-${i}`"
            :items="personList"
            item-value="id"
            item-name="Name"
            rules="required"
            v-model="value[i].staff_id"
          />
        </div>
        <div class="col-sm-3">
          <InputTime
            label="Time In"
            :vid="`timeIn-${i}`"
            v-model="value[i].timein"
            rules="required"
          />
        </div>

        <div class="col-sm-3">
          <InputTime
            label="Time Out"
            :vid="`timeOut-${i}`"
            v-model="value[i].timeout"
            rules="required"
          />
        </div>
      </FormRow>
    </RecordItem>
  </div>
</template>

<script>
import recordMixin1 from "Mixins/recordMixin1";
import { getStaffList } from "../api";
import moment from "moment";
export default {
  name: "AttendanceComponent",
  mixins: [recordMixin1],
  components: {},
  data() {
    return {
      personList: [],
    };
  },
  props: {
    readonly: Boolean,
  },
  computed: {
    userData() {
      return this.$store.state.user.user;
    },
  },
  watch: {
    value: {
      deep: true,
      handler(val) {
        if (this.value.length < 1) {
          this.pushEmptyValue();
        }
      },
    },
  },
  created() {
    this.staffList();
  },
  methods: {
    staffList() {
      getStaffList().then((res) => {
        this.personList = res.data;
      });
    },
    pushEmptyValue() {
      this.value.push({
        staff_id: "",
        timein: "",
        timeout: "",
        TimeIncreatedby: this.userData.user_id,
        TimeIncreatedon: moment().format("YYYY-MM-DD hh:mm:ss"),
        TimeOutCreatedby: this.userData.user_id,
        TimeOutCreatedOn: moment().format("YYYY-MM-DD hh:mm:ss"),
        SL_id: null,
        Redcross: null,
      });
    },
  },
};
</script>

<style lang="scss" module>
.indentClass {
  margin-left: 0px;
}
</style>
